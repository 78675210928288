import { InternationalizationType } from '../types/InternationalizationType'

const english: InternationalizationType = {
  global: {
    actions: 'Actions',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    delete: 'Delete',
    details: 'Details',
    isRequired: 'is a required field',
    status: 'Status',
    enable: 'Enable',
    disable: 'Disable',
    date: 'Date',
    time: 'Time',
    activities: 'Activities',
    download: 'Download',
    copyToClipboard: 'Copy to clipboard',
    copiedToClipboard: 'Copied to clipboard',
    combinations: {
      bathtub: 'Bathtub',
      fountain: 'Hand wash basin',
      radiator: 'Radiator',
      shower: 'Shower',
      tiles: 'Tiles',
      toilet: 'Toilet',
      utilities: 'Utilities',
      washbasin: 'Washbasin',
      undefined: 'No combination'
    },
    uploadFileTypes: {
      CUSTOM_OFFER: 'Custom offer',
      DRAWING3_D: '3d drawing',
      MANUAL: 'Manual',
      TECHNICAL_DRAWING: 'Technical drawing'
    },
    costTypes: {
      COORDINATION_COMMISSION_COSTS: 'Coordination commissions',
      CUSTOM_OFFER_COSTS: 'Customization',
      CV_INSTALLATION_COSTS: 'CV installation',
      DRAWING_COSTS: '3D Drawings',
      E_INSTALLATION_COSTS: 'E installation',
      INSTALLATION_CONSTRUCTION_COSTS: 'Installation construction',
      LOWER_PRICE_STANDARD_PRODUCTS_COSTS: 'Lower price standard products',
      PRODUCTS_AND_INSTALLATION_MATERIALS_COSTS: 'Products and installation materials',
      COORDINATION_COMMISSION_INSTALLER_COSTS: 'Coordination provision installer',
      COORDINATION_COMMISSION_BUILDER_COSTS: 'Coordination provision contractor',
      TILER_INSTALLATION_COSTS: 'Tiler installation',
      TILES_AND_FASTENERS_COSTS: 'Tiles and fasteners',
      TOTAL_SURCHARGE_COSTS: 'Total surcharge',
      VAT_COSTS: 'VAT',
      W_INSTALLATION_COSTS: 'W installation',
      KITTER_INSTALLATION_COSTS: 'Kitter'
    },
    selectionTypes: {
      CUSTOM: 'Custom selection',
      DESIGN_PACKAGE: 'Design package',
      SHELL_ONLY: 'Casco',
      STANDARD: 'Standard',
      GLOBAL_CUSTOM: 'Custom offer'
    },
    realEstateStatuses: {
      UNSOLD: 'Unsold',
      NEW_BUYER: 'New buyer',
      INVITED: 'Invited',
      BUYER_CHOOSING: 'Buyers choice',
      CUSTOM_OFFER: 'Custom offer',
      SEND_DOSSIER: 'Send dossier',
      COMPLETED: 'Completed',
      TECHNICAL_DRAWING: 'Technical drawing'
    },
    customOfferStatuses: {
      APPOINTMENT: 'Appointment planned',
      NEW: 'New',
      CANCELLED: 'Canceled',
      CREATE_OFFER: 'Create offer',
      REQUEST_FOR_CHANGE: 'Request for change',
      SHARED_WITH_CUSTOMER: 'Shared with customer',
      COMPLETED: 'Completed'
    },
    externalActionTargets: {
      CONTRACTOR: 'Contractor',
      INSTALLER: 'Installer',
      OTHER: 'Other'
    },
    customOfferGlobalAppointmentTypes: {
      CONTACT: 'Remote contact',
      MEETING: 'Physical appointment'
    },
    customOfferAppointmentTypes: {
      CONTACT: 'Call',
      MEETING: 'Physical'
    },
    customOfferContactTypes: {
      EMAIL: 'Email',
      NO_PREFERENCE: 'No preference',
      PHONE: 'Phone',
      NO_SELECTION: 'No selection'
    },
    selectionTypesDefaultSelectionName: {
      STANDARD: 'Standard configuration'
    },
    dialogs: {
      confirmation: {
        C4001: {
          description:
            'You are about to publish this project. The application will send invitations to all the participants and they will have access to this project. Are you sure you want to continue?',
          title: 'Confirm publishing project'
        },
        C4002: {
          description:
            'You are about to depublish this project. The project will become inaccessible to all participants, while they likely have already received invitations. Are you sure you want to continue?',
          title: 'Confirm unpublish'
        },
        C4003: {
          description:
            'You are about to close this project. The will be closed and become inaccessible to all participants. Are you sure you want to continue?',
          title: 'Confirm closing project'
        },
        C4004: {
          description:
            'You are about to reopen this project. The project will be placed in the concept phase and will still be inaccessible to participants. Are you sure you want to continue?',
          title: 'Confirm reopening project'
        },
        C4005: {
          description:
            'You are about to delete this design package. The design package will no longer be selectable and the content in the CMS will be unpublished. Are you sure you want to continue?',
          title: 'Confirm delete design package'
        },
        C4006: {
          description: 'You are about to delete a room. Are you sure you want to delete the following room?',
          title: 'Confirm delete room'
        },
        C4007: {
          description: 'You are about to resend the invitation. Are you sure you want to send now?',
          title: 'Confirm sending'
        },
        C4008: {
          description:
            'You are about to delete the project and all related data. This action is irreversible and will also remove all related data from the CMS. Are you sure you want to remove the project?',
          title: 'Confirm delete project'
        },
        C4009: {
          description:
            'You are about to reset the order for this real estate. This allows/requires the participant to (re)make his/her selection. Are you sure you want to reset now?',
          title: 'Confirm reset order'
        },
        C4010: {
          description:
            'You are about to cancel this custom offer request. The costumer will receive a notification. Are you sure you want to continue?',
          title: 'Confirm cancel request'
        },
        C4011: {
          description: 'Are you sure you want to change the appointment type?',
          title: 'Confirm appointment type change'
        },
        C4012: {
          description: 'Are you sure you want to change the request status?',
          title: 'Confirm status request change'
        },
        C4013: {
          description: 'Are you sure you want to cancel? The progress will not be saved to the offer.',
          title: 'Confirm cancel offer upsert'
        },
        C4014: {
          description: 'Are you sure you want to delete this custom offer?',
          title: 'Confirm delete custom offer'
        },
        C4015: {
          description:
            'You are about to reset the selections for this real estate. This allows/requires the participant to (re)make his/her selection. Are you sure you want to reset now?',
          title: 'Confirm reset selections'
        },
        C4016: {
          description: 'Are you sure you want to delete this product package?',
          title: 'Confirm delete product package'
        },
        C4017: {
          description: 'You are about to send the reminder. Are you sure you want to send now?',
          title: 'Confirm sending'
        },
        C4018: {
          description: 'You are about to send the invitation reminder. Are you sure you want to send now?',
          title: 'Confirm sending'
        },
        C4019: {
          description:
            'You are about to select Shell-Only for real estate. Are you sure you want to choose Shell-Only?',
          title: 'Confirm Shell-Only selection for real estate'
        },
        C4020: {
          description: 'You are about to select Standard for real estate. Are you sure you want to choose Standard?',
          title: 'Confirm Standard selection for real estate'
        },
        C4021: {
          description: 'You are about to complete this contact request. Are you sure you want to continue?',
          title: 'Confirm complete request'
        },
        C4022: {
          description:
            'You are about to change the subdivide product sets into categories behaviour. When active, the product sets without a category assigned will not be shown in the configuration. Are you sure you want to continue?',
          title: 'Confirm change in subdivide product sets into categories behaviour'
        },
        C4023: {
          description: 'You are about to delete this file. Are you sure you want to continue?',
          title: 'Confirm delete file'
        },
        C4024: {
          description: 'You are about to cancel this 3D drawing request. Are you sure you want to continue?',
          title: 'Confirm cancel this 3D drawing request'
        },
        C4025: {
          description:
            'You are about to send the dossier to this real estate again. You must include all the recipients as the link to download the files sent previously will become unavailable.',
          title: 'Confirm send dossier'
        },
        C4026: {
          description:
            'Pay attention: If you create a custom offer request for the customer, there will be costs for the customer and he will get a confirmation email of this request. Are you sure you want to continue?',
          title: 'Confirm request for custom offer'
        },
        C4027: {
          description:
            'Note: If you reject a technical drawing, this means that the currently issued custom offer is not correct. The status of the custom offer will change back to "request for change", so that the showroom advisor can make changes in a new offer. Please indicate below what needs to be changed:',
          title: 'Reject technical drawing'
        },
        C4028: {
          description: 'Are you sure you want to delete this activity for the current product set?',
          title: 'Confirm delete activity for product set'
        },
        C4029: {
          description: 'Are you sure you want to delete this activity template?',
          title: 'Confirm delete activity template'
        },
        C4030: {
          description: 'You are about to cancel this custom offer change request. Are you sure you want to continue?',
          title: 'Confirm cancel change request'
        },
        C4031: {
          description: 'By saving, the status of the custom offer request can change. Are you sure you want to save?',
          title: 'Confirm save custom offer'
        },
        C4032: {
          description: 'Are you sure you want to delete an appointment in the future?',
          title: 'Confirm delete appointment'
        },
        C4033: {
          description:
            'By completing the offer, costs will be added for customer unlike the "Cancel ofer" button. Are you sure you want to complete the custom offer request?',
          title: 'Confirm complete custom offer request'
        },
        C4034: {
          description: 'Are you sure you want to change the assigned showroom specialist?',
          title: 'Confirm showroom specialist change'
        },
        C4035: {
          description: 'Are you sure you want to share or unshare this custom offer with the customer?',
          title: 'Confirm (un)share with customer'
        },
        C4036: {
          description:
            'Are you sure you want to force the real estate? Costs have been made for services. When the real estate is forced, it will be changed to STANDARD+ or CASCO+ because services have been purchased.',
          title: 'Pay attention! Real estate contains costs for services.'
        },
        C4037: {
          description: 'Are you sure you want to delete this style?',
          title: 'Confirm delete style'
        },
        C4038: {
          description: 'Are you sure you want to delete this brand?',
          title: 'Confirm delete brand'
        },
        C4039: {
          description:
            'Are you sure you want to generate price variant desing packages? Non standard design packages settings will be overwriten.',
          title: 'Confirm generate price variant design packages'
        },
        C4040: {
          description: 'Are you sure you want to remove this style from the current project?',
          title: 'Confirm removing style from project'
        },
        C4041: {
          description: 'Are you sure you want to remove this brand from the current project?',
          title: 'Confirm removing brand from project'
        },
        C4042: {
          description:
            'Are you sure you want to update the selected styles for this project? Be aware that the design packages still need to (re)generated on the "Design packages" tab in order to create and/or remove design packages.',
          title: 'Confirm updating styles for project'
        },
        C4043: {
          description:
            'Are you sure you want to update the selected brands for this project? Be aware that the design packages still need to (re)generated on the "Design packages" tab in order to create and/or remove design packages.',
          title: 'Confirm updating brands for project'
        }
      },
      error: {
        E1101: {
          description: 'The selection name Standard already exists in this real estate.',
          title: 'Error forcing standard selection'
        },
        E1102: {
          description: "It's not possible to delete the product set because it's being used in an open project.",
          title: 'Error deleting product set'
        },
        E1108: {
          description: "Project status doesn't allow a Custom Offer request",
          title: 'Error creating custom offer request'
        },
        E1109: {
          description: "Real Estate status doesn't allow a Custom Offer request",
          title: 'Error creating custom offer request'
        },
        E1110: {
          description:
            'The removed characteristic(s) is/are still used in product sets. Clear the values of these characteristic(s) in the product sets that have them and then remove the characteristic(s) here.',
          title: 'Error removing characteristic(s) from product set group'
        },
        E1103: {
          description: "The product set can't be removed because it is a default product set in a design package.",
          title: 'Error deleting product set'
        },
        E1104: {
          description: 'You can only cancel a technical drawing request with a custom offer',
          title: 'Error cancelling technical drawing request'
        },
        E1105: {
          description: 'Something went wrong while deleting the activity template',
          title: 'Error deleting activity template'
        },
        E1106: {
          description: 'Something went wrong while creating the activity template',
          title: 'Error creating activity template'
        },
        E1107: {
          description: 'Something went wrong while updating the activity template name',
          title: 'Error updating activity template name'
        },
        E1111: {
          description: 'Something went wrong while cancelling the custom offer change request',
          title: 'Error cancelling custom offer change request'
        },
        E1112: {
          description: 'Something went wrong while creating an appointment date',
          title: 'Error creating appointment Date'
        },
        E1113: {
          description: 'Something went wrong while deleting an appointment date',
          title: 'Error deleting appointment Date'
        },
        E1114: {
          description: 'Something went wrong while editing an appointment date',
          title: 'Error editing appointment Date'
        },
        E1115: {
          description:
            "You can't share a custom offer if there are appointments planned in the future. Please update or delete them.",
          title: 'Error updating custom offer'
        },
        E1117: {
          description: "Something went wrong while updating the custom offer's share status",
          title: 'Error updating custom offer shared status'
        },
        E1118: {
          description: 'Something went wrong when retrieving the related category page',
          title: 'Error retrieving category page'
        },
        E1119: {
          description: 'No category page belonging to this category could be found',
          title: 'Error retrieving category page'
        },
        E4001: {
          description: 'Something went wrong while trying to log you in',
          title: 'Error signing in'
        },
        E4101: {
          description: 'Something went wrong while retrieving the projects',
          title: 'Error retrieving projects'
        },
        E4102: {
          description: 'Something went wrong when retrieving the styles',
          title: 'Error retrieving styles'
        },
        E4103: {
          description: 'Something went wrong when deleting the style',
          title: 'Error deleting style'
        },
        E4104: {
          description: 'Something went wrong when retrieving the related style page',
          title: 'Error retrieving style page'
        },
        E4105: {
          description: 'No style page belonging to this style could be found.',
          title: 'Error retrieving style page'
        },
        E4106: {
          description: 'Something went wrong retrieving the project details',
          title: 'Error retrieving project details'
        },
        E4107: {
          description: 'Something went wrong publishing the project',
          title: 'Error publishing project'
        },
        E4108: {
          description: 'Something went wrong unpublishing the project',
          title: 'Error unpublishing project'
        },
        E4109: {
          description: 'Something went wrong closing the project',
          title: 'Error closing project'
        },
        E4110: {
          description: 'Something went wrong reopening the project',
          title: 'Error reopening project'
        },
        E4111: {
          description: 'Something went wrong creating the new style',
          title: 'Error creating style'
        },
        E4112: {
          description: 'Something went wrong retrieving the project real estates',
          title: 'Error retrieving real estates'
        },
        E4113: {
          description: 'Something went wrong opening the project & real estate for preview',
          title: 'Error during preview'
        },
        E4114: {
          description: 'Something went wrong retrieving the rooms',
          title: 'Error retrieving rooms'
        },
        E4115: {
          description: 'Something went wrong deleting the room',
          title: 'Error deleting room'
        },
        E4116: {
          description: 'Something went wrong creating the new room',
          title: 'Error creating room'
        },
        E4117: {
          description: 'Something went wrong retrieving the styles assigned to this project',
          title: 'Error retrieving project styles'
        },
        E4118: {
          description: 'Something went wrong while updating the styles for this project',
          title: 'Error updating styles'
        },
        E4120: {
          description: 'Something went wrong retrieving the design package',
          title: 'Error retrieving design package'
        },
        E4121: {
          description: 'Something went wrong creating the new room',
          title: 'Error creating room'
        },
        E4122: {
          description: 'Something went wrong retrieving the design packages for the project',
          title: 'Error retrieving design packages'
        },
        E4123: {
          description: 'Something went wrong deleting the design package',
          title: 'Error deleting design package'
        },
        E4124: {
          description: 'Something went wrong retrieving the design packages for the project',
          title: 'Error retrieving design packages'
        },
        E4125: {
          description: 'Something went wrong updating the design package',
          title: 'Error updating design package'
        },
        E4126: {
          description:
            'It is not possible to create a design package with the same properties as any already existing design package',
          title: 'Error creating design package'
        },
        E4127: {
          description:
            'Could not find design package to edit. Did you navigate to this page using a stored url or a bookmark?',
          title: 'Design package not found'
        },
        E4128: {
          description: 'Something went wrong requesting the application to sync with the Estimate Feed.',
          title: 'Error syncing with Estimate'
        },
        E4129: {
          description: 'Something went wrong retrieving the room',
          title: 'Error retrieving room'
        },
        E4130: {
          description: 'Something went wrong updating the room',
          title: 'Error updating room'
        },
        E4131: {
          description: 'Something went wrong during the sending of the email.',
          title: 'Error sending email'
        },
        E4132: {
          description: 'Something went wrong deleting the project',
          title: 'Error deleting project'
        },
        E4133: {
          description: 'Something went wrong resetting the request for quote selection',
          title: 'Error resetting selection'
        },
        E4134: {
          description: 'Something went wrong when retrieving the related design package',
          title: 'Error retrieving design package'
        },
        E4135: {
          description: 'No design package belonging to this design package could be found.',
          title: 'Error retrieving design package'
        },
        E4140: {
          description: 'Something went wrong when retrieving the brands',
          title: 'Error retrieving brands'
        },
        E4141: {
          description: 'Something went wrong when deleting the brand',
          title: 'Error deleting brand'
        },
        E4142: {
          description: 'Something went wrong when retrieving the related style page',
          title: 'Error retrieving style page'
        },
        E4143: {
          description: 'No style page belonging to this brand could be found.',
          title: 'Error retrieving style page'
        },
        E4144: {
          description: 'Something went wrong retrieving the brands assigned to this project',
          title: 'Error retrieving project brands'
        },
        E4145: {
          description: 'Something went wrong while updating brands to the project',
          title: 'Error updating brands'
        },
        E4150: {
          description: 'Something went wrong when retrieving the product packages',
          title: 'Error retrieving product packages'
        },
        E4151: {
          description: 'Something went wrong when retrieving the related product package page',
          title: 'Error retrieving product package page'
        },
        E4152: {
          description: 'No product package page belonging to this product package could be found',
          title: 'Error retrieving product package page'
        },
        E4153: {
          description: 'Something went wrong retrieving the product packages assigned to this project',
          title: 'Error retrieving project product packages'
        },
        E4154: {
          description: 'Something went wrong assigned the product package to the project',
          title: 'Error assigning product package'
        },
        E4155: {
          description: 'Something went wrong unassigned the product package from the project',
          title: 'Error unassigned product package'
        },
        E4900: {
          description: 'The feature you are trying to access has not been implemented',
          title: 'Not implemented'
        },
        E4156: {
          description: 'Something went wrong retrieving the design packages product sets restrictions',
          title: 'Error retrieving design packages product sets restrictions'
        },
        E4157: {
          description: 'Something went wrong retrieving the standard product sets restrictions',
          title: 'Error retrieving standard product sets restrictions'
        },
        E4158: {
          description: 'Something went wrong when retrieving the related product brand line page',
          title: 'Error retrieving product brand line page'
        },
        E4159: {
          description: 'No product brand line page belonging to this product brand line could be found',
          title: 'Error retrieving product brand line page'
        },
        E4160: {
          description: 'Something went wrong updating the current product package',
          title: 'Error updating product package'
        },
        E4161: {
          description: 'Something went wrong updating the product set group',
          title: 'Error updating product set group'
        },
        E4162: {
          description: 'Something went wrong while creating a new category',
          title: 'Error creating category'
        },
        E4163: {
          description: 'Something went wrong while creating a new product package',
          title: 'Error creating product package'
        },
        E4164: {
          description: 'Something went wrong while deleting a category',
          title: 'Error deleting category'
        },
        E4165: {
          description: 'Something went wrong while deleting a product set',
          title: 'Error deleting product set'
        },
        E4166: {
          description: 'Something went wrong when retrieving the related product set page',
          title: 'Error retrieving product set page'
        },
        E4167: {
          description: 'No product set page belonging to this product set could be found',
          title: 'Error retrieving product set page'
        },
        E4168: {
          description: 'Something went wrong updating the margin value',
          title: 'Error updating margin value'
        },
        E4169: {
          description: 'Something went wrong with creating a new product set',
          title: 'Error creating new product set'
        },
        E4170: {
          description: 'Something went wrong with updating a product set',
          title: 'Error updating product set'
        },
        E4171: {
          description: 'Something went wrong with toggling a product set to mark as favourite/recommended',
          title: 'Error updating product set'
        },
        E4172: {
          description: 'Something went wrong with inviting the selected real estates',
          title: 'Error inviting real estates'
        },
        E4173: {
          description: 'Something went wrong while copying the product set',
          title: 'Error copying product set'
        },
        E4174: {
          description: 'Something went wrong with fetching the 3D drawing request',
          title: 'Error fetching 3D drawing request'
        },
        E4175: {
          description: 'Something went wrong uploading the 3D drawing file',
          title: 'Error uploading the 3D drawing file'
        },
        E4176: {
          description: 'Something went wrong with fetching the product package imports',
          title: 'Error fetching product package imports'
        },
        E4177: {
          description: 'Something went wrong uploading the product package',
          title: 'Error uploading the product package file'
        },
        E4178: {
          description: 'Something went wrong with fetching the 3D drawing requests',
          title: 'Error fetching 3D drawing requests'
        },
        E4179: {
          description: 'Something went wrong with fetching all the product set details',
          title: 'Error fetching product set details'
        },
        E4180: {
          description: 'Something went wrong when retrieving the product set groups',
          title: 'Error retrieving product set groups'
        },
        E4181: {
          description: 'Something went wrong with creating a new product set group',
          title: 'Error creating product set group'
        },
        E4182: {
          description: 'Something went wrong with updating the product set group',
          title: 'Error updating product set group'
        },
        E4183: {
          description: 'Something went wrong with retrieving the prices',
          title: 'Error retrieving prices'
        },
        E4184: {
          description: 'Something went wrong with updating the prices',
          title: 'Error updating prices'
        },
        E4186: {
          description: 'Something went wrong changing the custom offer setting',
          title: 'Error changing custom offer setting'
        },
        E4187: {
          description: 'Something went wrong fetching the real estate order details',
          title: 'Error fetching real estate order'
        },
        E4188: {
          description: 'Something went wrong changing the product set status',
          title: 'Error changing the product set status'
        },
        E4189: {
          description: 'Something went wrong downloading the project cost breakdown file',
          title: 'Error downloading the project cost breakdown file'
        },
        E4190: {
          description: 'Something went wrong with fetching the custom offer details',
          title: 'Error fetching custom offer details'
        },
        E4191: {
          description: 'Something went wrong with updating custom offer',
          title: 'Error updating custom offer'
        },
        E4192: {
          description: 'Something went wrong with uploading files for custom offer',
          title: 'Error uploading files for custom offer'
        },
        E4193: {
          description: 'Something went wrong with cancelling the custom offer request',
          title: 'Error cancelling custom offer request'
        },
        E4194: {
          description: 'Something went wrong changing the use return price setting',
          title: 'Error changing use return price setting'
        },
        E4195: {
          description: 'Something went wrong downloading the file for custom offer',
          title: 'Error downloading file for custom offer'
        },
        E4196: {
          description: 'Something went wrong while deleting the custom offer',
          title: 'Error deleting custom offer'
        },
        E4197: {
          description: 'Something went wrong while updating the prices of the selected product sets',
          title: 'Error updating product sets'
        },
        E4198: {
          description: 'Something went wrong while copying a new product package',
          title: 'Error copying product package'
        },
        E4199: {
          description: 'Something went wrong while resetting the real estate selections',
          title: 'Error resetting real estate selection'
        },
        E4200: {
          description: 'Something went wrong while deleting the product package',
          title: 'Error deleting product package'
        },
        E4201: {
          description: 'Something went wrong while enabling/disabling the notifications for the real estate.',
          title: 'Error enabling/disabling notifications'
        },
        E4202: {
          description: 'Something went wrong while trying to send the notification to the real estate.',
          title: 'Error sending notification'
        },
        E4203: {
          description: 'Something went wrong while trying to confirm selection to the real estate.',
          title: 'Error confirming real estate selection'
        },
        E4204: {
          description: 'Something went wrong while creating a new characteristic.',
          title: 'Error creating characteristic'
        },
        E4205: {
          description: 'Something went wrong while retrieving the contact requests',
          title: 'Error retrieving contact requests'
        },
        E4206: {
          description: 'Something went wrong while trying to update the contact request status.',
          title: 'Error updating contact request status'
        },
        E4207: {
          description: 'Something went wrong while trying to upload the file.',
          title: 'Error uploading file'
        },
        E4208: {
          description: 'Something went wrong while trying to delete the file.',
          title: 'Error deleting file'
        },
        E4209: {
          description: 'Something went wrong while trying to send the dossier.',
          title: 'Error sending dossier'
        },
        E4210: {
          description: 'Something went wrong while canceling a drawing request',
          title: 'Error cancelling drawing request'
        },
        E4211: {
          description: 'Something went wrong while downloading the project overview file',
          title: 'Error downloading the project overview file'
        },
        E4212: {
          description: 'Something went wrong while retrieving the details for this real estate',
          title: 'Error retrieving details'
        },
        E4214: {
          description: 'Something went wrong while creating the custom offer request',
          title: 'Error creating custom offer request'
        },
        E4215: {
          description: 'Something went wrong while retrieving the pending question/action for this real estate',
          title: 'Error retrieving external question/action'
        },
        E4216: {
          description: 'Something went wrong while creating an external question/action',
          title: 'Error creating external question/action'
        },
        E4217: {
          description: 'Something went wrong while updating the external question/action',
          title: 'Error updating external question/action'
        },
        E4218: {
          description: 'Something went wrong while resolving the external question/action',
          title: 'Error resolving external question/action'
        },
        E4221: {
          description: 'Something went wrong while changing the divide custom offer by rooms setting',
          title: 'Error changing divide custom offer by rooms setting'
        },
        E4222: {
          description: 'Something went wrong while updating the list of email addresses for the dossier',
          title: 'Error updating email addresses for dossier'
        },
        E4223: {
          description: 'Something went wrong while creating a new type of tile',
          title: 'Error creating new tile'
        },
        E4224: {
          description: 'Something went wrong while retrieving installation activities for this project',
          title: 'Error retrieving installation activities'
        },
        E4225: {
          description: 'Something went wrong while updating installation activity costs',
          title: 'Error updating installation costs'
        },
        E4226: {
          description: 'There was an error while fetching the technical drawings',
          title: 'Error fetching technical drawings'
        },
        E4227: {
          description: 'There was an error while fetching the available drawers',
          title: 'Error fetching drawers'
        },
        E4228: {
          description: 'There was an error while uploading the technical drawing',
          title: 'Error uploading technical drawing'
        },
        E4229: {
          description: 'There was an error while downloading the technical drawing',
          title: 'Error downloading technical drawing'
        },
        E4230: {
          description: 'There was an error while assigning a drawer to this request for 3D drawing',
          title: 'Error assigning drawer'
        },
        E4231: {
          description: 'There was an error while assigning a drawer to this request for technical drawing',
          title: 'Error assigning drawer'
        },
        E4232: {
          description: 'There was an error while importing the activity template',
          title: 'Error importing template'
        },
        E4233: {
          description: 'Something went wrong while retrieving activities',
          title: 'Error retrieving activities'
        },
        E4234: {
          description: 'Something went wrong while retrieving activity templates',
          title: 'Error retrieving activity templates'
        },
        E4235: {
          description: 'There was an error downloading the technical drawing because it does not exist',
          title: 'Error downloading technical drawing'
        },
        E4236: {
          description: 'Something went wrong with completing the custom offer request',
          title: 'Error completing custom offer request'
        },
        E4237: {
          description: 'Something went wrong while downloading the template file for import activities',
          title: 'Error downloading import activities template file'
        },
        E4238: {
          description: `Something went wrong while generating price variant design packages.`,
          title: 'Error generating price variant design packages'
        },
        E4239: {
          description: 'Something went wrong while copying a new brand',
          title: 'Error copying brand'
        },
        E4240: {
          description: 'Something went wrong while copying a new style',
          title: 'Error copying style'
        },
        E4241: {
          description: 'Something went wrong while fetching the categories',
          title: 'Error fetching categories'
        },

        EB1111: {
          description:
            'There is another product set already assigned for price variant "{{PRICEVARIANT}}". Remove the other before setting a new product set as preselected for a price variant.',
          title: 'Error assigning preselected for price variant'
        },
        EB1112: {
          description: `There are some preselected product sets missing for some price variants: "{{MISSING_PRESELECTED}}". 
            Set preselected product sets for price variants on these Styles/Brands and try again.`,
          title: 'Error generating some price variant design packages'
        },
        EB1113: {
          description: `There are some standard design packages that still miss some price variants: "{{MISSING_PROJECT_VARIATION_DESIGNPACKAGES}}".`,
          title: 'Error publishing project'
        }
      },
      notification: {
        N4001: {
          description:
            'A project can only be published if all real estates have at least one configured design package for each room. This is currently not the case.',
          title: 'Publishing not possible'
        },
        N4002: {
          description:
            'This project has no selected styles. Please select one or more styles first before continuing to add design packages.',
          title: 'No styles selected on project'
        },
        N4003: {
          description:
            'This project has no configured rooms. Please create one or more rooms first before continuing to add design packages.',
          title: 'No rooms configured on project'
        },
        N4004: {
          description:
            'This room still has design packages defined for it. Please remove these design packages before deleting this room',
          title: 'Room has design packages'
        },
        N4005: {
          description: 'You probably just pressed a button. This feature has not been implemented yet.',
          title: 'Not implemented yet'
        },
        N4006: {
          description:
            'The application has started to synchronize. It can take some time (minutes at most) for this operation to complete.',
          title: 'Synchronisation started'
        },
        N4007: {
          description: 'The application has successfully requested the sending of an email.',
          title: 'Email request sent'
        },
        N4008: {
          description:
            'The application has successfully created an external preview link and copied this link (Ctrl+C) to the clipboard. This link is now available for pasting (Ctrl+V) anywhere and will be valid for 14 days.',
          title: 'External preview link'
        },
        N4009: {
          description:
            'This project has no selected brands. Please select one or more brands first before continuing to add design packages.',
          title: 'No brands selected on project'
        },
        N4010: {
          description:
            'This project has no selected product packages. Please select one or more product packages first before continuing to add design packages.',
          title: 'No product packages selected on project'
        }
      },
      upload: {
        title: 'File Upload',
        uploadButton: 'Upload',
        errors: {
          maxFileSize: 'File exceeds the maximum upload size of {MAX} MB',
          invalidType: 'Invalid file type',
          required: 'File is required',
          duplicateName: 'This name already exists',
          invalidName: 'The name contains invalid characters',
          maxNameSize: 'The name exceeds the maximum size of {MAX} characters.',
          invalidEmails: 'One or more emails are not in the correct format.'
        }
      }
    },
    edit: 'Edit',
    copy: 'Copy',
    forms: {
      requiredFields: '* required field',
      save: 'Save',
      search: 'Search',
      import: 'Import rooms',
      textarea: {
        maxLength: 'The content must have a maximum length of 500 characters.'
      }
    },
    login: 'Login',
    signOut: 'Sign out',
    redirecting: 'Please wait a while. You are being redirected.',
    no: 'No',
    optional: 'optional',
    readonly: "can't be changed",
    remove: 'Remove',
    shellOnly: 'Shell only',
    standard: 'Standard',
    preselected: 'Select a {{CATEGORYNAME}} preselected',
    yes: 'Yes',
    validationMoney: 'Must be a positive integer or decimal, smaller than one million',
    validationPercentage: 'Must be a percentage, between 0% and 100%',
    validationDecimalPlaces2: 'Must be a positive value with a maximum of 2 decimal places',
    validationRequired: 'Is a required field',
    validationDuplicateName: 'This name already exists',
    validationLengthMeasure: 'Must be a positive integer or decimal, smaller than one million',
    validationAreaMeasure: 'Must be a positive integer or decimal',
    returnPrice: 'Return price',
    defaultProductSets: 'Use chosen default product sets for project',
    editContent: 'Edit content',
    save: 'Save',
    generateQR: 'Generate QR code',
    toasts: {
      actionSuccessful: 'Action was successful'
    }
  },
  header: {
    logout: 'Logout',
    projects: 'Projects',
    styles: 'Styles',
    brands: 'Brands',
    requests: 'Requests',
    drawings: 'Drawings',
    technicalDrawings: 'Technical drawings',
    customOffers: 'Custom offers',
    contactRequests: 'Contact',
    tools: 'Tools',
    welcome: 'Welcome',
    products: 'Products',
    proposition: 'Proposition',
    application: 'Application',
    categories: 'Product set groups',
    activities: 'Activities templates',
    masterProductPackage: 'Manage Master Product Package'
  },
  logout: {
    text: 'You are logged out, and can log back in, or close this window.',
    title: 'Logged out'
  },
  project: {
    list: {
      noDataText: 'No projects found',
      syncWithEstimate: 'Synchronize with Estimate',
      searchPlaceholder: 'Project name, project number, build number or project developer',
      table: {
        lastImportedOn: 'Imported',
        name: 'Name',
        projectDeveloper: 'Project developer',
        realEstateCount: 'Real estates',
        realEstateUninvitedCount: 'Uninvited',
        requestForQuoteCount: 'RFQs',
        status: 'Status',
        projectType: 'Proposition'
      },
      title: 'Projects',
      toProject: 'To project',
      hideClosed: 'Hide closed projects'
    },
    status: {
      closed: 'Closed',
      ended: 'Ended',
      open: 'Open',
      preview: 'Preview',
      unknown: 'Unknown'
    },
    proposition: {
      DE_BADKAMERWERELDEN: 'Style',
      MERKENPROPOSITIE: 'Brand',
      PRODUCTENPROPOSITIE: 'Product'
    }
  },
  projectDesignPackage: {
    create: {
      backToOverview: 'Back to overview',
      nameSelect: 'Select a price variant',
      positionToolTip:
        'Fill in a number to determine the position. The customer portal will display the design package based on this position.',
      roomSelect: 'Select a room',
      styleSelect: 'Select a style',
      brandSelect: 'Select a brand',
      title: 'Create new design package',
      productPackageSelect: 'Select a product package',
      standardCheckBox: 'Standard',
      standardToolTip: 'If selected, this design package will be defined as standard.',
      productCombinationForRoom: 'Activate product combinations for this room',
      configure: 'Configure'
    },
    edit: {
      nameSelect: 'Selected price variant',
      nameToolTip:
        'Unfortunately it is not possible to select a different price variant for an existing design package. It is possible to remove this design package and create a new one.',
      roomSelect: 'Selected room',
      roomToolTip:
        'Unfortunately it is not possible to select a different room for an existing design package. It is possible to remove this design package and create a new one.',
      styleSelect: 'Selected style',
      styleToolTip:
        'Unfortunately it is not possible to select a different style for an existing design package. It is possible to remove this design package and create a new one.',
      brandSelect: 'Selected brand',
      brandToolTip:
        'Unfortunately it is not possible to select a different brand for an existing design package. It is possible to remove this design package and create a new one.',
      title: 'Edit design package',
      standardToolTip:
        'Unfortunately it is not possible to change the standard for an existing design package. It is possible to remove this design package and create a new one.',
      productPackageSelect: 'Selected product package',
      productPackageToolTip:
        'Unfortunately it is not possible to select a different product package for an existing design package. It is possible to remove this design package and create a new one.'
    },
    errors: {
      room: {
        required: 'Room is a required field.'
      },
      style: {
        required: 'Style is a required field.'
      },
      brand: {
        required: 'Brand is a required field.'
      },
      name: {
        alphaNumSpace: 'Price variant may only contain alpha-numeric characters.',
        maxLength: 'Price variant has a maximum length of 20 characters.',
        required: 'Price variant is a required field.'
      },
      position: {
        integer: 'Position can only be an integer.',
        minValue: 'Position must be zero or larger.',
        required: 'Position is a required field.'
      },
      totalPrice: {
        money: 'Total price must be a positive integer or decimal, smaller than one million.',
        required: 'Total price is a required field.'
      },
      invalidCurrency: 'Invalid currency',
      productPackage: {
        required: 'Product package is a required field.'
      },
      productsAndInstallationMaterialsPrice: {
        money:
          'Products and installation materials price must be a positive integer or decimal, smaller than one million.',
        required: 'Products and installation materials price is a required field.'
      },
      coordinationCommissionPrice: {
        money: 'Coordination commission price must be a positive integer or decimal, smaller than one million.',
        required: 'Coordination commission price is a required field.'
      },
      coordinationCommissionInstallerPrice: {
        money:
          'Coordination commission installer price must be a positive integer or decimal, smaller than one million.',
        required: 'Coordination commission installer price is a required field.'
      },
      coordinationCommissionBuilderPrice: {
        money:
          'Coordination commission Constructor price must be a positive integer or decimal, smaller than one million.',
        required: 'Coordination commission Constructor price is a required field.'
      },
      installationConstructionPrice: {
        money: 'Installation construction price must be a positive integer or decimal, smaller than one million.',
        required: 'Installation construction price is a required field.'
      },
      tilesAndFastenersPrice: {
        money: 'Tiles and fasteners price must be a positive integer or decimal, smaller than one million.',
        required: 'Tiles and fasteners price is a required field.'
      },
      cvInstallationPrice: {
        money: 'Installation CV price must be a positive integer or decimal, smaller than one million.',
        required: 'Installation CV price is a required field.'
      },
      eInstallationPrice: {
        money: 'Installation E price must be a positive integer or decimal, smaller than one million.',
        required: 'Installation E price is a required field.'
      },
      wInstallationPrice: {
        money: 'Installation W price must be a positive integer or decimal, smaller than one million.',
        required: 'Installation W price is a required field.'
      },
      combinations: {
        selectedCombination: 'Select at least one product combination.'
      }
    },
    labels: {
      bath: 'Bath',
      toilet: 'Toilet',
      variant: 'Price Variant',
      position: 'Position',
      totalPrice: 'Total price',
      fromPrice: 'From price',
      room: 'Room',
      layout: 'Layout',
      shellOnly: 'Shell Only',
      style: 'Style',
      xl: 'XL',
      brand: 'Brand',
      productPackage: 'Product package',
      standard: 'Standard',
      productCombinations: 'Product combinations',
      productsAndInstallationMaterialsPrice: 'Products and installation materials price',
      coordinationCommissionPrice: 'Coordination commission price',
      coordinationCommissionInstallerPrice: 'Coordination commission Installer price',
      coordinationCommissionBuilderPrice: 'Coordination commission Constructor price',
      installationConstructionPrice: 'Construction price',
      tilesAndFastenersPrice: 'Tiles and fasteners price',
      cvInstallationPrice: 'Installation CV price',
      eInstallationPrice: 'Installation E price',
      wInstallationPrice: ' Installation W price',
      designPackageName: 'Name',
      restrictionKeys: {
        WIDTH: 'Max width',
        LENGTH: 'Max length',
        DEPTH: 'Max depth',
        HEIGHT: 'Max height',
        POSITION_TAPS: 'Built in taps possible',
        ELECTRICITY: 'Electricity for mirror',
        ALLOW_SHOWER_TOILET: 'Showertoilet possible',
        BASIC_INCLUDED: 'Combination basic in room',
        RESERVOIR_TYPE: 'Type built in reservoir',
        RADIATOR_TYPE: 'Radiator type',
        RADIATOR_CAPACITY: 'Minimum capacity in room',
        CORNER_BASIN: 'Corner basin',
        ALLOW_BATHTYPE_WHIRLPOOL: 'Whirlpool bath possible',
        ALLOW_BATHTYPE_FREESTANDING: 'Freestanding bath possible',
        ALLOW_DRAINAGE_GUTTER: 'Gutter drainage possible',
        ALLOW_DRAINAGE: 'Drainage possible',
        ALLOW_POSITION_TAPS_BUILTIN: 'Built in possible',
        MIN_WIDTH: 'Standard width ',
        MIN_LENGTH: 'Standard length ',
        MIN_DEPTH: 'Standard depth ',
        IS_SHOWER_DOOR_BETWEEN_WALLS: 'Only with niche door',
        ALLOWED_SHOWER_ENCLOSURE_TYPE: 'Shower enclosure types possible',
        FLOOR_AREA: 'Area of floor, including shower floor',
        TOILET_SURROUND_AREA: 'Area of toilet casing',
        TOILET_SURROUND_LINEAR: 'Linear meters toilet casing (m)',
        STANDARD_WALL_TILE_HEIGHT: 'Standard wall tile height'
      },
      walls: {
        title: 'Walls',
        addWall: '+ Add wall',
        defaultTileLabel: 'Default tile',
        defaultWallTileLabel: 'No separate default tile',
        preselectedTileLabel: 'Preselected tile',
        preselectedWallTileLabel: 'No separate preselected tile',
        tableHeaders: {
          name: 'Name',
          area: 'Tiles m2 ',
          profileLength: 'Linear meters profile',
          ceilingArea: 'Ceiling m2',
          ceilingLength: 'Linear meters ceiling',
          accentWall: 'Accent wall',
          defaultAccentTile: 'Standard tile',
          preselectedAccentTile: 'Preselected tile'
        }
      }
    },
    list: {
      addDesignPackage: 'Add design package',
      generateVariationDesignPackages: 'Generate variation design packages',
      editContent: 'Edit Content',
      roomFilter: 'Filter by Room',
      styleFilter: 'Filter by Style',
      brandFilter: 'Filter by Brand',
      title: 'Design packages',
      productPackageFilter: 'Filter by Product package',
      standardFilter: 'Filter by standard'
    }
  },
  projectDetails: {
    labels: {
      shellOnlyAllowed: 'Shell only allowed',
      closedOn: 'Closed on',
      designPackageCount: 'Design packages',
      projectDeveloper: 'Project developer',
      publishedOn: 'Published on',
      lastImportedOn: 'Last imported on',
      layouts: 'Layouts',
      realEstateCount: 'Real estates',
      requestedQuoteCount: 'Requested quotes',
      rooms: 'Rooms',
      status: 'Status',
      brand: 'Brand',
      styles: 'Styles',
      productPackages: 'Product packages',
      enableCustomOffer: 'Custom offer',
      enableCustomOfferTooltipOn:
        'ON -  The custom offer is active for the project and the Customer can request it in the Customer Portal.',
      enableCustomOfferTooltipOff:
        'OFF - The custom offer is disabled and the Customer can not request in the Customer Portal.',
      splitCustomOfferByRooms: 'Split custom offer into rooms',
      splitCustomOfferByRoomsTooltipOn: 'ON -  The custom offer is split into rooms and can be chosen independently.',
      splitCustomOfferByRoomsTooltipOff: 'OFF - The custom offer is grouped into a single offer.',
      useReturnPriceCalculation: 'Deduct return price for coordination costs calculation',
      useReturnPriceCalculationTooltipOn:
        'ON -  The return price is deducted from the sale price to calculate the coordination costs.',
      useReturnPriceCalculationTooltipOff: 'OFF - The return price is ignored to calculate the coordination costs.',
      useProjectActivityCosts: 'Use project activities costs',
      useProjectActivityCostsTooltipOn:
        'ON -  The installation costs are calculated based on the activities assigned to each product set.',
      useProjectActivityCostsTooltipOff:
        'OFF - The installation costs are calculated based on product set costs and consider the installation corrections.',
      dossierEmails: 'Email addresses for dossier',
      dossierEmailsPlaceholder: 'Fill in an email address and press enter',
      margins: {
        companyMarginSanitary: 'Company project margin sanitary',
        companyMarginTiles: 'Company project margin tiles',
        marginInstaller: 'Extra project margin installer sanitary',
        marginTiler: 'Extra project margin Tiler tiles',
        marginBuilder: 'Extra project margin builder sanitary and tiles',
        correctionInstallationWFactor: 'Extra correction installation W prices',
        correctionInstallationEFactor: 'Extra correction installation E prices',
        correctionInstallationCVFactor: 'Extra correction installation CV prices',
        correctionTilerFactor: 'Extra correction tiler prices',
        correctionConstructionFactor: 'Extra correction construction prices'
      }
    },
    errors: {
      percentagePositive: 'Value must be a number between 0 and 100',
      percentageAllowNegative: 'Value must be a number between -100 and 100',
      invalidEmail: 'The email address is in an invalid format',
      duplicateEmail: 'This email address already exists'
    },
    title: 'Project details',
    downloadCostsButton: 'Download cost breakdown',
    downloadOverviewButton: 'Download overview',
    downloadOverviewFile: 'Overview'
  },
  projectDetailView: {
    close: 'Close project',
    enrich: 'Enrich',
    unpublish: 'Depublish',
    publish: 'Publish',
    reopen: 'Reopen',
    tabs: {
      designPackages: 'Design packages',
      project: 'Project',
      realEstates: 'Real estates',
      rooms: 'Rooms',
      styles: 'Styles',
      brands: 'Brands',
      productPackages: 'Product Packages',
      articleReturnPrices: 'Return prices'
    },
    firstTitle: 'Choose a project to import information from',
    secondTitle: 'Choose a layout',
    paragraph: 'Rooms with this layout will be copied and linked to the previously selected layout.'
  },
  projectRealEstates: {
    inviteSelectedRealEstates: 'Invite selected real estates',
    preview: 'Preview',
    realEstateOrder: 'View order',
    externalPreview: 'External preview',
    resetOrder: 'Reset order',
    resetSelections: 'Reset selections',
    resetSelectionsInfo: 'Reset the order to enable this option.',
    enableNotifications: 'Enable notifications',
    disableNotifications: 'Disable notifications',
    externalAction: 'External action',
    uploadDocuments: 'Upload documents',
    sendDossier: 'Send dossier',
    sendNotificationSubmenu: {
      title: 'Send Notification',
      sendReminder: 'Send Reminder',
      sendInvitationReminder: 'Send Invitation Reminder'
    },
    confirmSelection: {
      title: 'Confirm selection',
      casco: 'Select shell-only',
      standard: 'Select standard'
    },
    table: {
      buildNumber: 'Build nr.',
      deadline: 'Deadline',
      contactDetail: 'Contact',
      invitationSent: 'Invitation',
      lastLogin: 'Login',
      hasRequestForQuote: 'Confirmed',
      hasNotifications: 'Notifications',
      offerNumber: 'Offer nr.',
      layout: 'Layout',
      externalAction: 'Action',
      dossierSent: 'Dossier',
      status: {
        label: 'Status',
        tooltips: {
          lastChanged: 'Last changed'
        }
      },
      customOffer: {
        label: 'Custom offer',
        tooltips: {
          appointment: 'Appointment',
          status: 'Status',
          flagTooltip: 'Follow-up Offer'
        }
      }
    },
    title: 'Real estates',
    labels: {
      lastNoLoginNotification: 'No login',
      lastNoQuoteNotification: 'No quote',
      notificationsDisabledInfo: 'Notifications are disabled'
    },
    view: {
      contactName: 'Contact name',
      hasBath: 'With bath',
      isXL: 'XL',
      lastLogin: 'Last login',
      noLastLogin: 'Never',
      noQuotesRequested: 'No',
      quotesRequested: 'Quote requested',
      requestForQuotes: 'Selected design packages',
      title: 'Real estate details',
      contactFormalName: 'Formal name'
    },
    confirmedStatus: {
      additional_work: 'Additional work',
      casco: 'Casco',
      standard: 'Standard',
      casco_plus: 'Casco+',
      standard_plus: 'Standard+'
    },
    uploadPopup: {
      subtitle: 'Upload documents for build number ',
      filename: 'Filename',
      filesSubtitle: 'Real estate documents',
      noFilesText: "Real estate doesn't have any document yet",
      recipients: 'Recipients (separated by ";")'
    },
    sendDossierPopup: {
      title: 'Send dossier',
      subtitle: 'Send dossier for build number ',
      submitButton: 'Send dossier'
    }
  },
  realEstateDetails: {
    backButtonText: 'Back to overview',
    buildNumber: 'Build number',
    status: 'Status',
    closingDate: 'Closing date',
    contact: 'Contact information',
    contactDetails: {
      email: 'Email address',
      legalName: 'Name buyer',
      phone: 'Telephone',
      invitedDate: 'Invited on',
      lastLogin: 'Last login'
    },
    realEstate: 'Details real estate',
    realEstateDetails: {
      offerNumber: 'Offer number',
      layout: 'Layout',
      rooms: 'Rooms',
      notificationsEnabled: 'Notifications enabled'
    },
    notifications: {
      lastNoLoginNotification: 'Reminder login',
      lastNoQuoteNotification: 'Reminder choice confirmation'
    },
    requestForQuote: 'Choices buyer',
    requestForQuoteDate: 'Requested on',
    noChoices: 'No order has been sent yet',
    selectedDesignPackages: 'Selected design packages',
    customOffer: 'Custom offer request',
    customOfferDetails: {
      status: 'Status',
      detailsLink: 'Click here to go to the details of the custom offer request',
      noCustomOffer: 'Currently there are no active custom offer requests'
    },
    dossierSent: 'Date dossier sent',
    noDossierSent: 'The dossier has not been sent yet',
    files: 'Documents',
    noFiles: 'There are no uploaded documents (yet)',
    notes: 'Notes contact requests',
    noteDetailsLink: 'Click here to go to the details of this note',
    noNotes: 'There are no notes (yet)',
    externalQuestions: 'External questions',
    noExternalQuestions: 'There are no external questions yet',
    externalQuestionsQuestion: 'Question',
    externalQuestionsAnswer: 'Answer'
  },
  projectRoom: {
    create: {
      layouts: 'Add room to layouts',
      roomName: 'Room name',
      roomPosition: 'Room position',
      title: 'Add rooms to this project'
    },
    edit: {
      layouts: 'Room applies to following layouts',
      roomName: 'Room name',
      roomPosition: 'Room position',
      title: 'Edit room for this project'
    },
    errors: {
      name: {
        alphaNumSpace: 'Name may only contain alphanumeric characters, possibly separated by spaces.',
        isUnique: 'Name must be unique within the scope of this project.',
        maxLength: 'Name has a maximum length of 40 characters.',
        required: 'Name is a required field.'
      },
      position: {
        integer: 'Position can only be an integer.',
        minValue: 'Position must be zero or larger.',
        required: 'Position is a required field.'
      },
      selectedLayouts: {
        required: 'Select at least one layout'
      }
    },
    list: {
      table: {
        designPackages: 'Design packages',
        layouts: 'Layouts',
        name: 'Name',
        position: 'Position',
        title: 'Stored rooms'
      },
      title: 'Rooms'
    }
  },
  projectStyles: {
    list: {
      title: 'Styles for this project',
      description: 'A project requires a minimum of 1 style.',
      addedDesignPackage: 'design package added',
      addedDesignPackages: 'design packages added',
      selectStyle: 'Select style',
      noDataText: 'No styles were found',
      tableHeaders: {
        name: 'Name',
        designPackageCount: 'Design packages'
      }
    }
  },
  style: {
    create: {
      errors: {
        name: {
          isUnique: 'Name must be unique.',
          maxLength: 'Name has a maximum length of 50 characters.',
          required: 'Name is a required field.'
        }
      },
      form: {
        styleName: 'Name of style *'
      },
      title: 'Add style'
    },
    copy: {
      errors: {
        name: {
          isUnique: 'Name must be unique.',
          maxLength: 'Name has a maximum length of 50 characters.',
          required: 'Name is a required field.'
        }
      },
      form: {
        styleName: 'Name of style *',
        defaultFieldValue: '(Copy)'
      },
      title: 'Copy style'
    },
    list: {
      addStyle: 'Add style',
      editContent: 'Edit content',
      viewImports: 'View imports',
      title: 'Manage styles',
      copy: 'Copy',
      edit: 'Edit'
    }
  },
  projectBrands: {
    list: {
      title: 'Brand for this project',
      description: 'A project requires a brand. Select it here.',
      addedDesignPackage: 'design package added',
      addedDesignPackages: 'design packages added',
      selectBrand: 'Select brand',
      noDataText: 'No brands were found',
      tableHeaders: {
        name: 'Name',
        designPackageCount: 'Design packages'
      }
    }
  },
  brand: {
    create: {
      errors: {
        name: {
          isUnique: 'Name must be unique.',
          maxLength: 'Name has a maximum length of 50 characters.',
          required: 'Name is a required field.'
        }
      },
      form: {
        styleName: 'Name of brand *'
      },
      title: 'Add brand'
    },
    copy: {
      errors: {
        name: {
          isUnique: 'Name must be unique.',
          maxLength: 'Name has a maximum length of 50 characters.',
          required: 'Name is a required field.'
        }
      },
      form: {
        brandName: 'Name of brand *',
        defaultFieldValue: '(Copy)'
      },
      title: 'Copy brand'
    },
    list: {
      edit: 'Edit',
      copy: 'Copy',
      viewImports: 'View imports',
      addBrand: 'Add brand',
      editContent: 'Edit content',
      title: 'Manage brands'
    }
  },
  projectArticleReturnPrices: {
    title: 'Return prices',
    searchPlaceholder: 'Search on article code or description',
    list: {
      headers: {
        code: 'Article code',
        description: 'Description',
        combination: 'Combination',
        returnPrice: 'Return price'
      },
      noDataText: 'No article prices found',
      noResultsText: 'No article prices found',
      emptyFieldsWarning: 'It looks like not all articles have a return price.',
      returnPricePlaceholder: 'xxxx,xx'
    }
  },
  activities: {
    table: {
      headers: {
        activity: 'Activity',
        combination: 'Combination',
        CV_INSTALLATION_COSTS: 'Installation CV',
        E_INSTALLATION_COSTS: 'Installation E',
        W_INSTALLATION_COSTS: 'Installation W',
        INSTALLATION_CONSTRUCTION_COSTS: 'Construction',
        TILER_INSTALLATION_COSTS: 'Tiler',
        KITTER_INSTALLATION_COSTS: 'Kitter'
      },
      activityTarget: {
        PRODUCT_SET: 'Costs per product set',
        SQUARE_METERS: 'Costs per square meters (m²)',
        METERS: 'Costs per meters (m)'
      },
      noDataText: 'There are no activities for this project yet',
      noResultsText: 'We couldn`t find any activities based on your search term',
      validations: {
        money: 'Must be a positive decimal value'
      }
    },
    import: 'Select a default',
    importDialog: {
      title: 'Import Activity Template',
      label: 'Name of activity template',
      confirm: 'Import'
    }
  },
  editActivityTemplate: {
    title: 'Edit activity template',
    table: {
      headers: {
        activity: 'Activity Template'
      },
      noDataText: 'There are no activity templates for this project yet',
      noResultsText: 'We couldn`t find any activity Template based on your search term'
    },
    errors: {
      name: {
        maxLength: 'Name has a maximum length of 150 characters.',
        required: 'Name is a required field.'
      }
    },
    backToOverview: 'Back to overview'
  },
  productPackage: {
    create: {
      errors: {
        name: {
          isUnique: 'Name must be unique.',
          maxLength: 'Name has a maximum length of 50 characters.',
          required: 'Name is a required field.'
        }
      },
      form: {
        productPackageName: 'Name of product package *'
      },
      title: 'Add product package'
    },
    copy: {
      errors: {
        name: {
          isUnique: 'Name must be unique.',
          maxLength: 'Name has a maximum length of 50 characters.',
          required: 'Name is a required field.'
        }
      },
      form: {
        productPackageName: 'Name of product package *',
        defaultFieldValue: '(Copy)'
      },
      title: 'Copy product package'
    },
    imports: {
      title: 'Imports product packages',
      addImport: 'Import product package',
      getTemplate: 'Download template',
      getTemplateFilename: 'Import product package template file',
      validations: {
        maxSize: 'This field has a maximum length of 150 characters'
      },
      form: {
        productPackageName: 'Name product package'
      },
      list: {
        headers: {
          productPackageName: 'Product package',
          fileName: 'File name',
          uploadDate: 'Upload date',
          path: 'Path',
          status: 'Status',
          finishDate: 'Finish date',
          information: 'Information'
        }
      }
    },
    list: {
      viewProductPackageImports: 'View imports',
      addProductPackage: 'Add product package',
      editContent: 'Edit content',
      edit: 'Edit',
      copy: 'Copy',
      title: 'Manage product packages',
      isMainTooltip:
        'This is a main product package. This means it can not be deleted, and only product sets from a main product package can be used to create QR codes.'
    },
    edit: {
      title: 'Manage product package',
      fields: {
        name: {
          label: 'Name',
          placeholder: 'Product package name'
        }
      },
      category: {
        brandLine: {
          allowBrands: 'Subdivide into categories',
          name: 'Category name',
          add: 'Add category'
        },
        productSets: {
          add: 'Add product set',
          copy: {
            errors: {
              name: {
                maxLength: 'Name has a maximum length of 150 characters.',
                required: 'Name is a required field.'
              }
            },
            form: {
              productSetName: 'Name of product set *',
              defaultFieldValue: '(Copy)'
            },
            title: 'Copy product set'
          },
          upsert: {
            title: 'Add/update product set',
            subtitle: 'Add/update product set from product set group ',
            fields: {
              brandline: {
                placeholder: 'Category'
              },
              installationCosts: {
                ifCombinationIsStandard: 'If combination is standard',
                ifCombinationIsNotStandard: 'If combination is not standard',
                CV_INSTALLATION_COSTS: {
                  label: 'Installation costs CV'
                },
                E_INSTALLATION_COSTS: {
                  label: 'Installation costs E'
                },
                W_INSTALLATION_COSTS: {
                  label: 'Installation costs W'
                },
                INSTALLATION_CONSTRUCTION_COSTS: {
                  label: 'Construction costs'
                },
                TILER_INSTALLATION_COSTS: {
                  label: 'Costs tiler'
                }
              },
              isDefault: {
                label: 'Default'
              },
              name: {
                placeholder: 'Name'
              },
              productCode: {
                label: 'Product code',
                noDataText: 'No products found'
              },
              withoutProducts: {
                label: 'Without products'
              },
              activities: {
                label: 'Activity name',
                noDataText: 'No activities found'
              },
              priceVariants: {
                label: 'Set as pre-selected for price variant',
                categoryLabel: 'Category'
              }
            },
            productListTable: {
              code: 'Code',
              name: 'Name',
              primary: 'Primary',
              tilesCostPrice: 'Price',
              tilesLinearPrice: 'm1 price'
            },
            priceVariantsListTable: {
              priceVariant: 'Price variant',
              categoryName: 'Category'
            },
            priceTypes: {
              METERS: 'M1',
              SQUARE_METERS: 'M2',
              PRODUCT_SET: 'Product'
            },
            sections: {
              costs: 'Costs',
              products: 'Products',
              properties: 'Properties',
              customProperties: 'Custom properties',
              brandline: 'Category',
              priceVariants: 'Pre-selected in price variants'
            },
            addProductBtn: 'Add new product',
            addPriceVariantBtn: 'Add price variant',
            validations: {
              money: 'Must be a positive decimal value',
              numeric: 'Must be a non-negative integer value',
              decimal: 'Must be a non-negative decimal value',
              percentage: 'Must be a percentage between 0 and 100',
              decimalPlaces2: 'must be a positive value with a maximum of 2 decimal places',
              required: 'This field is required',
              maxsize: 'This field has a maximum length of 150 characters',
              color: 'This field needs to be a valid color code',
              maxValue_1000: 'Must be a positive integer or decimal, smaller than 1000',
              maxValue_100000: 'Must be a positive integer or decimal, smaller than 100000',
              maxValue_1000000: 'Must be a positive integer or decimal, smaller than 1000000'
            }
          },
          list: {
            noDataText: 'No product sets have been found',
            table: {
              category: 'Category',
              name: 'Product set name',
              price: 'Price',
              priceVariant: 'Price variant',
              available: 'Available',
              disabled: 'Disabled',
              disabledQrExplanation:
                'This is not a main product package. You can only generate QR codes of product sets from main product packages.'
            }
          },
          addProductModal: {
            title: 'Add new type of tile',
            fields: {
              code: 'Product code',
              name: 'Name tile',
              price: 'Price',
              supplierCode: 'Product code supplier',
              priceType: 'Price per'
            },
            validations: {
              maxSize: 'This field has a maximum length of 150 characters',
              money: 'Must be a positive decimal value',
              required: 'This field is required'
            }
          },
          bulkEdit: {
            bulkEditButton: 'Update selection',
            modalTitle: 'Update selected product sets',
            modalSubtitle: 'Update the prices of the selected product sets',
            modalExplanation: '* Only filled in fields will be updated. Not filled in fields will remain the same.',
            ifCombinationIsStandard: 'If combination is standard',
            ifCombinationIsNotStandard: 'If combination is not standard'
          }
        }
      },
      dialogs: {
        brandLine: {
          delete: {
            title: 'Delete category',
            text: 'Are you sure you want to delete this category?'
          }
        },
        productSet: {
          delete: {
            title: 'Delete product set',
            text: 'Are you sure you want to delete this product set?'
          }
        }
      },
      errors: {
        brandLine: {
          isUnique: 'Name must be unique within this category.',
          maxLength: 'Name has a maximum length of 100 characters.',
          required: 'Name is a required field.'
        }
      },
      backToOverview: 'Back to overview'
    }
  },
  activityTemplate: {
    title: 'Activities Templates',
    addActivityTemplate: 'Add activity template',
    addImport: 'Import activity template',
    importActivityTemplateDialog: {
      placeholder: 'Name of activity template *',
      validations: {
        maxSize: 'This field has a maximum length of 150 characters'
      }
    },
    getTemplate: 'Download template',
    getTemplateFilename: 'Import project activities template file',
    addActivityDialog: {
      title: 'Add activity template',
      placeholder: 'Name of activity template *',
      validations: {
        maxSize: 'This field has a maximum length of 150 characters'
      }
    },
    list: {
      edit: 'Edit'
    }
  },
  projectProductPackage: {
    list: {
      title: 'Product packages for this project',
      description: 'A project requires at least one product package selected. Select it here.',
      addedDesignPackage: 'design package added',
      addedDesignPackages: 'design packages added',
      notEditable:
        'The selected product package cannot be changed when there are still some design packages with existing product package.'
    }
  },
  drawings: {
    title: '3D drawings',
    list: {
      noDataText: 'There are no requests for 3D drawings',
      noResultsText: "We couldn't find any drawings based on your search criteria",
      searchPlaceholder: 'Project name, build number or contact person',
      statusFilterPlaceholder: 'Filter by drawing status',
      drawerFilterPlaceholder: 'Filter by drawer',
      hideCanceledCompletedLabel: 'Hide canceled/completed drawings',
      uploadDrawing: 'Upload 3D Design',
      assignDrawer: 'Assign drawer',
      downloadDrawing: 'Download 3D Design',
      headers: {
        requested: 'Requested',
        project: 'Project',
        buildNr: 'Build nr',
        room: 'Room',
        designName: 'Design',
        contact: 'Contact',
        email: 'Email',
        assignedDrawerName: 'Drawer',
        deadline: 'Deadline',
        status: 'Status'
      }
    },
    status: {
      REQUESTED: 'New',
      READY: 'Ready',
      CANCELLED: 'Cancelled',
      REQUESTED_AND_CANCELABLE: 'New and Cancelable',
      PENDING: 'Pending',
      IN_PROGRESS: 'In progress'
    },
    uploadPopup: {
      subtitle: 'Upload 3D Drawing file for',
      inputPlaceholder: 'Pick file'
    }
  },
  technicalDrawings: {
    title: 'Technical drawings',
    list: {
      noDataText: 'There are no requests for technical drawings',
      noResultsText: "We couldn't find any drawings based on your search criteria",
      searchPlaceholder: 'Project name, build number or contact person',
      statusFilterPlaceholder: 'Filter by drawing status',
      drawerFilterPlaceholder: 'Filter by drawer',
      hideCanceledCompletedLabel: 'Hide canceled/completed drawings',
      uploadDrawing: 'Upload technical drawing',
      downloadDrawing: 'Download technical drawing',
      assignDrawer: 'Assign drawer',
      headers: {
        requested: 'Requested on',
        project: 'Project',
        buildNr: 'Build nr',
        room: 'Rooms',
        designName: 'Name design',
        contact: 'Contact',
        drawer: 'Drawer',
        email: 'Email',
        deadline: 'Deadline',
        status: 'Status'
      }
    },
    status: {
      REQUESTED: 'New',
      READY: 'Completed',
      CANCELLED: 'Cancelled',
      REQUESTED_AND_CANCELABLE: 'New and Custom Offer',
      PENDING: 'Pending',
      IN_PROGRESS: 'In progress'
    },
    uploadPopup: {
      subtitle: 'Upload technical drawing file for',
      inputPlaceholder: 'Select a file',
      project: 'project',
      buildNr: 'with build number'
    },
    assignDrawerPopUp: {
      subtitle: 'Assign a drawer for',
      inputPlaceHolder: 'Assign drawer'
    },
    reject: 'Reject'
  },
  assignDrawer: {
    title: 'Assign drawer',
    subtitle: 'Assign a drawer by selecting a drawer from the list and clicking the save button.',
    selectDrawerPlaceholder: 'Select a drawer'
  },
  drawingRequestDetails: {
    title: 'Details 3D drawing',
    designNamePrefix: 'All details for drawing',
    buildNumber: 'Build number',
    roomName: 'Room',
    drawingRequestedOn: 'Drawing requested on',
    drawingStatus: 'Status',
    projectName: 'Project'
  },
  customOffers: {
    title: 'Custom offers requests',
    list: {
      noDataText: 'There are no custom offer requests',
      noResultsText: "We couldn't find any custom offers requests based on your search criteria",
      searchPlaceholder: 'Build number or email',
      filterStatusPlaceholder: 'Filter by custom offer request status',
      filterSpecialistPlaceholder: 'Filter by showroom specialist',
      newOfferRequestButton: 'New request',
      hideCanceledCompleted: 'Hide canceled/completed requests',
      headers: {
        appointmentType: 'Type',
        requestedOn: 'Requested on',
        project: 'Project',
        buildNr: 'Build nr',
        rooms: 'Rooms',
        email: 'Email',
        specialist: 'Specialist',
        closingDate: 'Closing date',
        status: 'Status'
      }
    },
    newRequestDialog: {
      title: 'Create custom offer request',
      appointmentType: 'Appointment type',
      project: 'Project',
      buildNumber: 'Build number',
      requestNotes: 'Request notes'
    }
  },
  customOfferDetails: {
    title: 'Custom offer - Build number ',
    closingDate: 'Closing date',
    backButtonText: 'Back to overview',
    cancelButtonText: 'Cancel offer',
    cancelChangeRequestButtonText: 'Cancel change request',
    completeOfferButtonText: 'Complete offer',
    tooltipText: 'You can only cancel a change request if a custom offer is shared',
    reopenButtonText: 'Reopen',
    appointmentType: 'Appointment type',
    assignedSpecialist: 'Showroom specialist',
    scheduledDates: 'Scheduled dates',
    noAppointments: 'There are no Appointments yet',
    dateAppointmentButton: '+ Appointment',
    contact: 'Contact',
    contactDetails: {
      name: 'Name',
      email: 'email address',
      phone: 'Phone'
    },
    requestDetailsLabel: 'Request details',
    requestDetails: {
      requestedDate: 'Requested date',
      contactPreference: 'Contact preference',
      rooms: 'Rooms'
    },
    notes: 'Request notes',
    offersList: {
      title: 'Offers',
      addButton: 'Add document',
      columns: {
        name: 'Name',
        rooms: 'Rooms',
        created: 'Created',
        sharedWithCustomer: 'Shared with customer',
        downloadFiles: 'Download files'
      }
    },
    upsertOfferPopup: {
      titleAdd: 'Add offer',
      titleEdit: 'Edit offer',
      subtitle: 'Add an offer for all rooms',
      name: 'Offer name',
      link: 'Link to 360 visual',
      uploadDrawingBtn: 'Upload drawing file',
      uploadOfferBtn: 'Upload offer file',
      shareWith: 'Share with customer',
      costSpecification: {
        title: 'Cost specification',
        globalOfferColumnName: 'From offer',
        productsAndMaterials: 'Products and materials',
        tilesAndMaterials: 'Tiles and materials',
        lowerPriceStandardProducts: 'Return price standard products',
        services: 'Services debadkamer.nl',
        productsAndServicesSubtotal: 'Subtotal products and services',
        coordinationComissionBuilder: 'Coordination provision contractor',
        coordinationComissionInstaller: 'Coordination provision installer',
        coordinationProvisionSubtotal: 'Subtotal coordination provision',
        installationConstruction: 'Construction of sanitary installation',
        kitter: 'Kitter',
        tiler: 'Tiler',
        wInstallation: 'Installation of sanitary products',
        eInstallation: 'Installation electra',
        cvInstallation: 'Installation radiator',
        installationAndConstructionsubtotal: 'Subtotal installation and construction',
        totalExclVat: 'Total excl. V.A.T.',
        vat: 'V.A.T. 21%',
        totalInclVat: 'Total incl. V.A.T',
        total: 'Total',
        validations: {
          required: 'This field is required',
          money: 'Must be a positive decimal value',
          negativeMoney: 'Must be zero or a negative decimal value'
        }
      }
    },
    addAppointmentModal: {
      title: 'Add new appointment',
      invalidTimeFormat: 'Valid time format should be hh:mm',
      invalidDateFormat: 'Valid date format should be yyyy-mm-dd'
    },
    editAppointmentModal: {
      title: 'Edit appointment'
    }
  },
  customerOrder: {
    customOfferLabel: 'Custom offer',
    name: 'Name',
    createdOn: 'Created on',
    buildNumber: 'Build number',
    layout: 'Layout',
    contact: 'Contact person',
    lastLogin: 'Last login',
    requestDate: 'Request date',
    costOverview: 'Cost overview',
    costItems: {
      priceProductsAndMaterials: 'Total products and materials',
      priceTilesAndFasteners: 'Total tiles and fasteners',
      priceServices: 'Total services',
      priceReturnPrices: 'Total return price standard products',
      subTotal_productsAndMaterials_services_returnPrices: 'Subtotal products and services',
      priceCoordinationProvisionInstaller: 'Coordination provision installer',
      priceCoordinationProvisionContractor: 'Coordination provision contractor',
      subTotal_coordinationProvisionInstaller_coordinationProvisionContractor: 'Subtotal coordination provision',
      priceConstructionCosts: 'Construction of sanitary installation',
      priceWInstallation: 'Installation of sanitary products',
      priceEInstallation: 'Installation electra',
      priceCVInstallation: 'Installation radiator',
      subTotal_priceConstructionCosts_priceWInstallation_priceEInstallation_priceCVInstallation_priceTiler_priceKitter:
        'Subtotal installation and construction',
      totalExVat: 'Total excl. V.A.T.',
      priceVat: 'V.A.T. 21%',
      totalIncVat: 'Total incl. V.A.T.',
      subTotal_productsAndMaterials_returnPrices: 'Subtotal products',
      priceTiler: 'Tiler',
      priceKitter: 'Kitter'
    },
    productChoices: 'Product choices',
    casco: 'Casco is chosen',
    downloadOffer: 'Download offer file',
    downloadDrawing: 'Download drawing'
  },
  components: {
    productSetCard: {
      articleCodeLabel: 'Article code',
      noArticlesLabel: 'This product set does not (yet) contain any products'
    },
    returnedProductSet: {
      title: '* Returned product sets',
      articleCodeLabel: 'Article code',
      noReturnedArticles: 'No returned articles'
    },
    priceTypes: {
      METERS: 'Linear meters',
      SQUARE_METERS: 'Square meters',
      PRODUCT_SET: 'Quantity'
    }
  },
  application: {
    categories: {
      title: 'Manage product set groups',
      list: {
        editContent: 'Edit content',
        edit: 'Edit',
        add: 'Add product set group',
        upsert: {
          edit: 'Update product set group',
          add: 'Add product set group',
          errors: {
            name: {
              isUnique: 'Name must be unique for each product combination.',
              required: 'Name is a required field.'
            },
            position: {
              required: 'Position is a required field.'
            }
          },
          infoKeys: {
            title: 'Product set characteristics',
            addBtn: 'Add characteristics',
            list: {
              headers: {
                name: 'Name',
                position: 'Position',
                displayable: 'Visible in details',
                filterable: 'Filterable'
              }
            },
            add: {
              title: 'Add characteristics',
              labelNL: 'Name NL',
              labelNLPlaceholder: 'Dutch name of characteristic',
              labelEN: 'Name EN',
              labelENPlaceholder: 'English name of characteristic',
              errors: {
                label: {
                  isUnique: 'This field must be unique',
                  required: 'This field is required'
                }
              }
            }
          }
        },
        headers: {
          name: 'Name',
          title: 'Title',
          translatedTitle: 'Translated title',
          position: 'Position',
          brandlineHeader: 'Category header',
          translatedBrandlineHeader: 'Translated category header',
          productCombination: 'Product combination',
          translatedName: 'Translated name'
        }
      }
    }
  },
  contactRequests: {
    title: 'Contact requests',
    list: {
      noDataText: 'There are no contact requests',
      noResultsText: "We couldn't find any contact request based on your search criteria",
      searchPlaceholder: 'Email',
      filterProjectPlaceholder: 'Filter by project',
      filterBuildNumberPlaceholder: 'Filter by build number',
      filterBuildNumberTooltip: 'Please select a project first',
      hideCompletedLabel: 'Hide completed requests',
      filterPlaceholder: 'Filter by request status',
      openRequest: 'Open request',
      headers: {
        requester: 'From',
        project: 'Project',
        buildNr: 'Build nr',
        status: 'Status',
        requestDate: 'Requested on',
        modifiedDate: 'Modified on'
      }
    },
    status: {
      new: 'New',
      pending: 'In Progress',
      closed: 'Completed'
    },
    details: {
      title: 'Contact request - Build number ',
      backButton: 'Back to overview',
      completeRequest: 'Complete request',
      requestDetails: 'Request details',
      modifiedDate: 'Last modification date',
      requestDate: 'Request date',
      requester: 'Request from',
      questionLabel: 'Question',
      answerLabel: 'Answer',
      closeContactRequestTitle: 'Complete Contact request',
      closeContactRequestSubtitle: 'Complete Contact request - Build number ',
      project: 'Project',
      buildNumber: 'Build nr',
      status: 'Status',
      phoneNumber: 'Phone number',
      contactPreference: 'Contact preference',
      contactPreferenceTypes: {
        EMAIL: 'Email',
        NO_PREFERENCE: 'No preference',
        PHONE: 'Phone'
      }
    }
  },
  externalActionDialog: {
    title: 'External action/question for build number',
    newActionSubtitle:
      'Create an external action or question that needs to be resolved before the project can be completed',
    pendingActionSubtitle: 'Here you can update the action/question or resolve it completely',
    target: 'Who needs to resolve/answer the action or question?',
    questionLabel: 'Action/question',
    answerLabel: 'Answer/note',
    createAction: 'Create action/question',
    updateAction: 'Update action/question',
    createAndResolveAction: 'Resolve action/question',
    resolveAction: 'Resolve action/question'
  },
  externalActionResolveDialog: {
    title: 'Complete external action/question',
    subtitle: 'Answer the question of add a note of the resolved action.',
    back: 'Back',
    confirm: 'Confirm'
  },
  customOfferFollowUpDialog: {
    title: 'Follow up offer',
    subtitle: 'Here you can write down what you discussed with the customer',
    noteLabel: 'Action / Note',
    cancel: 'Cancel',
    resolve: 'Resolve'
  },
  requestForChangeDialog: {
    title: 'Force change request',
    subtitle:
      'Note: If you force a change request, this means that the status of the custom offer changes back to change request. In this way it remains clear that a change to the custom offer is needed. State below which changes have been discussed with the customer: ',
    notesLabel: 'Notes',
    cancel: 'Cancel',
    submitChanges: 'Submit changes'
  }
}

export default english
